<template>
    <b-overlay :show="loading">
        <main>
            <!-- Header card -->
            <header class="mb-2">
                <b-row class="d-flex justify-content-center">
                    <b-col v-for="(value, label) in cardDebt" class="m-1 m-md-0" :key="label" sm="12" md="4" lg="3" xl="2">
                        <b-card style="min-height: 87%;max-height: 150px;position: relative;">
                            <div class="d-flex flex-wrap flex-col justify-content-center">
                                <h4 class="text-center text-danger mb-2" style="overflow: hidden;">
                                    <strong class="d-block text-center">Rp</strong>
                                    <strong>{{ formatRupiah(value) }}</strong>
                                </h4>
                                <strong class="font-medium d-block text-center w-100 pb-1" style="position: absolute;left: 0;bottom: 0;">
                                    {{ label.toUpperCase() }}
                                </strong>
                            </div>
                        </b-card>    
                    </b-col>
                </b-row>
            </header>
            <!-- / -->

            <!-- Daftar Akun Kategori Hutang -->
            <h3 class="mb-1">Akun Dengan Kategori Hutang <span v-if="akuns.length > 0">({{ akuns.length }})</span></h3>
            <b-card class="mb-4">
                <b-row>
                    <b-col md="6" sm="12" class="my-1">
                        <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            class="w-50"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="my-1">
                        <b-form-group
                        label="Filter"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                            />
                            <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Clear
                            </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
    
                    <b-col cols="12">
                        <b-table
                            striped
                            small
                            hover
                            responsive
                            :per-page="perPage"
                            :current-page="currentPage"
                            :items="akuns"
                            :fields="fields"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :sort-direction="sortDirection"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            @filtered="onFiltered"
                        >
                        <template #cell(no)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(pajak)="{item}">
                            {{ item.pajak }}%
                        </template>
                        <template #cell(jenis)="data">
                            <b-badge :variant="ket_jenis[1][data.value]">
                            {{ ket_jenis[0][data.value] }}
                            </b-badge>
                        </template>
                        <template #cell(hutang)="{item}">
                            <b-badge variant="light-info">
                            {{ item.hutang ? 'Ya' : 'Tidak' }}
                            </b-badge>
                        </template>
                        <template #cell(saldo)="{ item }">
                            <strong
                            >
                            {{
                                item.saldo >= 0 ? formatRupiah(item.saldo) : "( " + formatRupiah(item.saldo * -1 ) + " )"
                            }}</strong
                            >
                        </template>
                        <template #cell(nama)="{ item }">
                            <span @click.prevent="$router.push(`/akun/${item.id}?from=/hutang-perusahaan`)"><u>{{ item.nama }}</u></span>
                        </template>
                        <template #cell(nomor)="{ item }">
                            <span @click.prevent="$router.push(`/akun/${item.id}?from=/hutang-perusahaan`)"><u>{{ item.nomor }}</u></span>
                        </template>
    
                        <template #cell(actions)="row">
                            <b-button
                            size="sm"
                            @click="$router.push(`/akun/info/${row.item.id}?from=/hutang-perusahaan`)"
                            class="mr-1"
                            variant="outline-info"
                            >
                            <feather-icon icon="EyeIcon" />
                            </b-button>
                        </template>
    
                        <template #row-details="row">
                            <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">
                                {{ key }}: {{ value }}
                                </li>
                            </ul>
                            </b-card>
                        </template>
                        </b-table>
                    </b-col>
    
                    <b-col cols="12">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                        />
                    </b-col>
                </b-row>
            </b-card>
            <!-- / -->

            <!-- Pembelian yang belum lunas -->
            <pembelian-belum-lunas />
            <!-- / -->
        </main>
    </b-overlay>
</template>
<script>
import {BOverlay, BPagination, BTable, BButton, BBadge, BFormGroup, BInputGroup, BFormInput, BFormSelect, BInputGroupAppend, BCard, BRow, BCol} from 'bootstrap-vue'
import PembelianBelumLunas from './Pembelian.vue'
export default {
    components: {
        BOverlay, BPagination, BTable, BButton, BBadge, BFormGroup, BInputGroup, BFormInput, BFormSelect, BInputGroupAppend, BCard, BRow, BCol, PembelianBelumLunas
    },
    data: () => ({
        loading: false,
        cardDebt: [],
        akuns: [],
        fields: [
            { key: "no", label: "no" },
            { key: "nomor", label: "Kode Akun", sortable: true },
            { key: "nama", label: "Nama Akun", sortable: true },
            { key: "jenis", label: "jenis", sortable: true },
            { key: "saldo", label: "Nominal (Rp)", sortable: true },
            { key: "actions", label: "Aksi" }
        ],
        ket_jenis: [
            {
            1: "Debit",
            2: "Kredit",
            3: "Debit & Kredit",
            },
            {
            1: "light-success",
            2: "light-danger",
            3: "light-primary",
            },
        ],
        perPage: 100,
        pageOptions: [20, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
    }),
    methods: {
        async getCardDebt() {
            this.loading = true
            this.cardDebt = await this.$store.dispatch('app/getCompanyDebt')
            this.loading = false
        },
        async getAkun() {
            const params = {
                order: 'desc',
                hutang: 1
            }

            this.loading = true
            this.akuns = await this.$store.dispatch('akun/getData', params)
            this.loading = false
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        }
    },
    created() {
        this.getCardDebt()
        this.getAkun()
    }
}
</script>